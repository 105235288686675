.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 30px 0;
  position: relative;
  z-index: 99;
  &-left {
    display: flex;
    align-items: center;
    gap: 50px;
  }
}
